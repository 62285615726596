.page-product.overflow-hidden {
  overflow: hidden;
  height: 100%;
}

.product-introduce {
  width: 100%;
  height: 1084px;
  /*background: linear-gradient(260deg, rgba(234, 233, 255, 0.10) 100%, #FDFEFF 50.02%, rgba(231, 237, 255, 0.40) 5.15%);*/
  /*height: 400vh;*/
  background-color: #F9FAFF;
  /*background: url('../../common/img/animation_translateion_bg.png') no-repeat center / cover;*/
  /*background: linear-gradient(0deg, #FAFAFF 9.79%, #FEFEFF 24.62%, #F5F7FF 64.82%);*/
  position: relative;
  overflow: hidden;
}

.product-introduce-animation {
  /*height: 109vh;*/
  /*top: -90px;*/
  /*position: sticky;*/
  /*overflow: hidden;*/
}

.product-introduce video, .product-introduce .animation-person {
  position: absolute;
  width: 3200px;
  height: 1084px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.other-product-introduce {
  width: 100%;
  height: 2180px;
  background: linear-gradient(to bottom, #FFF 1.64%, #F8F8FB 39%, #EAEFFF 100.52%);
}

.introduce-content {
  position: relative;
  margin: auto;
  width: 1200px;
  height: 800px;
}

.introduce-content .container-right {
  width: 716px;
}

.introduce-content .container-right img {
  width: 100%;
}

.introduce-detail-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.introduce-detail {
  display: flex;
  flex-direction: column;
  color: #1D2331;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 504px;
  margin-top: 190px;
  /*left: 0;*/
}

.introduce-detail .name {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: 78px;
  background: linear-gradient(98deg, #3E71FF 41.57%, #7D71F2 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.introduce-detail .target {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
}

.introduce-detail .detail {
  opacity: 0.8;
  line-height: 28px;
  margin: 32px 0;
}

.introduce-detail .option button {
  border-radius: 12px;
  width: 180px;
  height: 52px;
  flex-shrink: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #1D2331;
  border: 1px solid #1D2331;
  background-color: transparent;
  margin-right: 12px;
}
.introduce-detail .option button:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.introduce-detail .option button.primary {
  color: #FFF;
  border: 0;
  background: linear-gradient(98deg, #3E71FF 41.57%, #7D72F2 100%);
}

.introduce-detail .option button.primary:hover {
  background: linear-gradient(98deg, #3866ED 41.57%, #6D67DA 100%);
}

.sub-product-list {
  width: 100%;
  overflow: hidden;
  height: 244px;
  position: absolute;
  top: 739px;
  transform-style: preserve-3d;
}

.sub-product-list .sub-product-card-list {
  /*width: 2840px;*/
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px 0 50px 2px;
}

.sub-product-list .sub-product-card-list::-webkit-scrollbar {
  width: 0; /* 设置滚动条宽度 */
  height: 0;
}

.sub-product-list .sub-product-card-list::-webkit-scrollbar-thumb {
  background-color: transparent; /* 设置滚动条颜色 */
}

.sub-product-list .sub-product-card-list li {
  width: 381px;
  height: 184px;
  border-radius: 12px;
  background: #fff url('../../common/img/icon_product_card_bg.png') no-repeat;
  display: inline-block;
  padding: 24px 32px 40px 32px;
  box-shadow: 0 20px 32px 0 rgba(0, 21, 50, 0.03);
  border: 1px solid #FFF;
  margin-right: 20px;
}

.sub-product-list .sub-product-card-list li .title {
  color: #1C212A;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  height: 48px;
}

.sub-product-list .sub-product-card-list li .title:before {
  content: '';
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.sub-product-list .sub-product-card-list li .title.icon-text:before {
  background: url('../../common/img/icon_card_item_text.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-img:before {
  background: url('../../common/img/icon_card_item_img.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-audio:before {
  background: url('../../common/img/icon_card_item_video.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-file:before {
  background: url('../../common/img/icon_card_item_file.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-audio-video:before {
  background: url('../../common/img/icon_card_item_rtc.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-si:before {
  background: url('../../common/img/icon_card_item_speak_simultaneously.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .title.icon-tmc:before {
  background: url('../../common/img/icon_card_item_model_custome.png') no-repeat center / cover;
}

.sub-product-list .sub-product-card-list li .detail {
  color: #1D2331;
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  white-space: pre-wrap;
}

.card-circle-box {
  position: absolute;
  left: 0;
  top: 220px;
  height: 10px;
  width: 100%;
}

.card-circle-box .card-circle {
  display: flex;
  gap: 24px;
}

.card-circle-box .card-circle li {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #C8CED7;
}

.card-circle-box .card-circle li.active {
  width: 8px;
  height: 8px;
  background: linear-gradient(98deg, #3E71FF 41.57%, #7D72F2 100%);
}

.application-scenario {
  padding: 60px 0;
}

.section-header {
  color: #1C212A;
  text-align: center;
  font-size: 44px;
  font-weight: 500;
}

.section-subheading {
  color: rgba(30, 35, 48, 0.4);
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  margin: 12px 0 36px;
}

.application-scenario ul {
  display: flex;
  gap: 21px 20px;
  flex-wrap: wrap;
}

.application-scenario ul li {
  width: 386px;
  height: 382px;
  border-radius: 12px;
  border: 1px solid #FFF;
  box-shadow: 0 20px 32px 0 rgba(0, 21, 50, 0.03);
  background: url('../../common/img/icon_application_bg.png') no-repeat;
  display: flex;
  flex-direction: column;
}

.application-scenario ul li .text {
  padding: 24px 32px;
  height: 184px;
}

.application-scenario ul li img {
  width: 100%;
}

.application-scenario ul li .title {
  color: #1C212A;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}

.application-scenario ul li .detail {
  color: rgba(30, 35, 48, .8);
  font-size: 16px;
  line-height: 28px;
}

.business-partner {
  padding: 60px 0;
}

.partner-logo-list {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
}

.partner-logo-list li {
  width: 183px;
  height: 51px;
  padding: 0 32px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.partner-logo-list li.active {
  background-color: #fff;
}

.partner-logo-list li img {
  width: 120px;
  height: 33px;
}

.swiper-content {
  overflow: hidden;
}

.partner-detail {
  width: 100%;
  height: 483px;
  background: transparent url('../../common/img/icon_partner_bg.png') no-repeat;
  border-radius: 8px;
}

.partner-detail {
  display: flex;
}

.partner-introduce {
  width: 666px;
  padding: 114px 104px 0  104px;
}

.partner-logo {
  width: 216px;
  height: 48px;
  margin-bottom: 36px;
}

.partner-logo.logo-yuewen {
  background: url('../../common/img/yuewen_logo.png') no-repeat center / cover;
}

.partner-logo.logo-tencent-qcloud {
  background: url('../../common/img/tencent_qcloud_logo.png') no-repeat center / cover;
}

.partner-logo.logo-ua {
  background: url('../../common/img/ua_logo.png') no-repeat center / cover;
}

.partner-introduce-text {
  color: rgba(30, 35, 48, 0.8);
  font-size: 16px;
  line-height: 28px;
}

.partner-img {
  width: 534px;
}

.partner-img img {
  width: 100%;
}

.content-bottom {
  padding: 50px 0;
}

.content-detail {
  display: flex;
  justify-content: space-between;
}

.content-bottom .list-item {
  margin-right: 80px;
}

.content-bottom .list-item .title {
  color: #1D2331;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
}

.content-bottom .list-item li {
  color: #4B596B;
  margin-bottom: 6px;
  line-height: 24px;
}

.content-bottom .list-item li a {
  color: #4B596B;
}

.content-bottom .list-item li a:hover {
  text-decoration: none;
}

.content-bottom .content-right img {
  width: 360px;
  height: 178px;
}

@keyframes productIntroduce {
  0% {
    transform: translate3d(0, 0px, 0);
  }
  100% {
    transform: translate3d(-2807px, 0px, 0);
  }
}
