.nav-content {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  height: 62px;
}

.nav-content.one-screen {
  background: color(display-p3 1 1 1 / 0.70);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.03);
}

.nav-content.scroll-bg {
  background: color(display-p3 1 1 1 / 0.70);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(50px);
}

.container {
  width: 1200px;
  margin: auto;
}

.nav-container {
  height: 62px;
  display: inline-flex;
  justify-content: space-between;
}

.nav-left {
  color: #1E2330;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.nav-left img {
  width: 180px;
  height: 44px;
}

.nav-left .title {
  margin-left: 8px;
}

.nav-right li {
  display: inline-flex;
  padding: 0 12px;
  align-items: center;
  width: 80px;
  height: 62px;
}

.nav-right li.active a,  .nav-right li:hover a{
  color: #1D2331;
  font-weight: 500;
}

.nav-right li a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #030A26A6;
}

.nav-right li a:hover {
  text-decoration: none;
}

.nav-right li.outside-link {
  width: 94px;
}

.nav-right li.outside-link a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outside-link a:after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('./common/img/icon_link.svg') no-repeat;
  margin-left: 2px;
  cursor: pointer;
}

.nav-right li.outside-link:hover a:after {
  background: url('./common/img/icon_link_hover.svg') no-repeat;
}
