.page-demo {
  padding: 94px 0 54px;
  justify-content: center;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  background: #FAFAFA url('../../common/img/icon_content_bg.png') no-repeat center center / cover;
}

.demo-container {
  width: 1220px;
}

.window-modal-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 3;
  background-color: rgba(32, 38, 59, .4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.window-modal-tips .tips-img {
  width: 580px;
  height: 268px;
  position: relative;
  cursor: pointer;
}

.tips-btn-close {
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.5) url('../../common/img/icon_close.svg') no-repeat center;
  position: absolute;
  border-radius: 99px;
  right: 0;
  top: -12px;
  padding: 3px;
  cursor: pointer;
}

.window-modal-tips .tips-img .img {
  width: 100%;
  height: 100%;
  background: url('../../common/img/brand_upgrading_bg.png') no-repeat 0 0 / cover;
}

.header-tab {
  width: 402px;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid rgba(7, 43, 82, 0.08);
  background: #FFF;
  margin-bottom: 16px;
  font-size: 16px;
}

.header-tab li {
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 500;
  line-height: 24px;
  color: #06154233;
  display: flex;
  position: relative;
  width: 122px;
}

.header-tab li .title:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 2px;
  margin-bottom: -4px;
}

.header-tab li.image .title:before {
  background: url('../../common/img/icon_tab_image.png') no-repeat 0 0 / cover;
}

.header-tab li.document .title:before {
  background: url('../../common/img/icon_tab_document.png') no-repeat 0 0 / cover;
}

.header-tab li.text.current .title:before {
  width: 22px;
  height: 22px;
  background: url('../../common/img/icon_tab_text.svg') no-repeat;
}

.header-tab li.current {
  color: #fff;
  background: linear-gradient(98deg, #3E71FF 41.57%, #7D72F2 100%);
}

.header-tab li.notOnline:hover {
  cursor: not-allowed;
}

.header-tab li.notOnline:hover:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url('../../common/img/icon_start.svg') no-repeat;
  position: absolute;
  top: -6px;
  right: 36px;
}

.header-tab li.notOnline:hover:after {
  content: '敬请期待';
  display: inline-block;
  position: absolute;
  right: -6px;
  top: -11px;
  font-size: 10px;
  font-weight: 400;
  color: #0FB87F;
}

.translate-content {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  transition: height 0.3s ease;
}

.content-box {
  width: 600px;
  min-height: 400px;
  border-radius: 12px;
  border: 1px solid #122A4F14;
  background: #FFF;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  box-sizing: border-box;
  position: relative;
}

.content-left {
  display: flex;
}

.content-right {
  display: flex;
}

.content-header {
  border-radius: 6px;
  margin-bottom: 16px;
  margin-left: -12px;
  padding-left: 12px;
}

.content-header:hover {
  background-color: #F7F8FA;
}

.content-header .tea-icon-arrowdown {
  width: 16px;
  height: 16px;
  background: url('../../common/img/icon_down_arrow.svg') no-repeat;
}

.content-header .tea-dropdown__value {
  font-size: 14px;
}

.drop-down-box {
  width: 546px;
  border-radius: 12px;
  box-shadow: 0 0 16px 0 rgba(53, 58, 82, 0.12);
}

.drop-down-box .tea-card {
  border-radius: 12px;
  box-shadow: none;
}

.drop-down-box .tea-list {
  display: flex;
  flex-wrap: wrap;
}

.drop-down-box .tea-list > li {
  text-align: center;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  color: #000;
  width: 33%;
}

.drop-down-box .tea-list > li:hover {
  background: #F4F5F9;
}

.content-input {
  width: 100%;
  min-height: 322px;
  font-size: 16px;
  height: 100%;
}

.content-input.input-right {
  padding-bottom: 35px;
}

.content-input .tea-textarea-group {
  position: static;
  padding-bottom: 24px;
}

.content-input .tea-textarea-group, .content-input .tea-textarea {
  border: 0;
  height: 100%;
  line-height: 150%;
  color: #00010AED;
  transition: height 0.3s ease;
}

.content-input .tea-textarea {
  padding: 0;
}

.content-input .tea-textarea-group .tea-textarea__label {
  font-size: 14px;
  color: #010B3269;
  bottom: 29px;
  right: 22px;
}

.content-input .target-text-area {
  height: 100%;
  position: relative;
}

.content-input .placeholder {
  color: rgba(2, 21, 69, 0.30);
  position: absolute;
}

.target-text-box {
  height: 100%;
  min-height: 33px;
  overflow-y: scroll;
}

.target-text-box.not-show::-webkit-scrollbar {
  width: 0; /* 设置滚动条宽度 */
  height: 0;
  display: none;
}

.target-text-box.not-show {
  -ms-overflow-style: none; /* 隐藏 IE 浏览器的滚动条 */
  scrollbar-width: none; /* 隐藏 Firefox 浏览器的滚动条 */
}


.target-text-box::-webkit-scrollbar-thumb {
  background-color: transparent; /* 设置滚动条颜色 */
}

.content-input .target-text-list {
  min-height: 33px;
}

.content-input .source-text-list {
  color: #4A70FF;
}

.content-input.input-left {
  display: flex;
  gap: 4px;
}

.content-header-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content-header-box .icon-clear {
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: -8px;
}

.content-header-box .icon-clear:before {
  content: '';
  width: 24px;
  height: 24px;
  background: url('../../common/img/icon_clear.svg') no-repeat center center / cover;
  display: block;
}

.icon-clear:hover {
  background-color: #F7F8FA;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-16 {
  font-size: 16px;
}

.content-option {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  bottom: 13px;
  left: 0;
}

.content-option .tea-icon-copy {
  width: 24px;
  height: 24px;
  background: url('../../common/img/icon_copy.svg') no-repeat;
}

.content-option .tea-icon-copy:hover {
  background-color: #F7F8FA;
  border-radius: 8px;
}

.btn-content-box {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-compare {
  width: 24px;
  height: 24px;
  background: url('../../common/img/icon_compare.svg') no-repeat;
  cursor: pointer;
}

.btn-content-box:hover {
  background-color: #F7F8FA;
  border-radius: 8px;
}

.btn-compare.active {
  background: url('../../common/img/icon_compare_active.svg') no-repeat;
}

.icon_check {
  display: inline-flex;
  gap: 2px;
}

.icon_check:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../common/img/icon_check.svg') no-repeat;
}

#tea-overlay-root .tea-overlay {
  border-radius: 6px;
}

#tea-overlay-root .tea-bubble .tea-bubble__inner {
  color: #fff;
  background-color: #363a50;
  border-color: #363a50;
  border-radius: 6px;
  padding: 6px 8px 6px 6px;
  line-height: 16px;
  font-size: 12px;
  height: 28px;
}

#tea-overlay-root .tea-bubble.tea-bubble--bottom .tea-bubble__inner:after, #tea-overlay-root .tea-bubble.tea-bubble--bottom .tea-bubble__inner:before {
  border-top-color: #363a50;
}

.error-message {
  background-color: #FFF3F1;
  font-size: 12px;
  border-color: #FDE0DC;
  border-radius: 6px;
}

.error-message .tea-icon-error {
  background: url('../../common/img/icon_error_tips.svg') no-repeat;
}
